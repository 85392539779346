
import { signOut } from "firebase/auth";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { removeToken } from "../app/features/login/authSlice";
import { auth } from "./firebase";


const db = getFirestore();

export const monitorSession = (userId, navigate,dispatch) => {
  const sessionRef = doc(db, "sessions", userId);

  onSnapshot(sessionRef, (docSnapshot) => {
    const sessionData = docSnapshot.data();
    console.log('session data', sessionData);
    
    // Check if session is inactive
    if (!sessionData?.isActive) {
      console.log('inactive session, logging out user from here');
      
      // Log the user out from Firebase
      signOut(auth)
        .then(() => {
          console.log("Logged out due to another login");
          // Clear localStorage
          localStorage.clear();
          // Clear Redux state (logout action)
          dispatch(removeToken()); // Reset Redux state
          // Redirect the user to the login page
          // window.location.href = 'https://dealer.ratefry.com/login'; // Make sure to add 'http://' or 'https://'
          navigate("/admin/login", { replace: true });
          window.location.reload()
        })
        .catch((error) => {
          console.error("Error logging out: ", error);
        });
    }
  });
};

