
import { axiosInstance } from "./axios";

// export const getSingleAdminDetails = async () => {
//   try{
//     const response = await axiosInstance.get(`/admin/get/by/token`);
//     return response.data;
//   }catch(err){
//       console.log('err',err)
//   }
//   };

  export const getSingleAdminDetails = async () => {
    try {
      const response = await axiosInstance.get(`/admin/get/by/token`);
      return response.data;
    } catch (err) {
      // Check if the error response contains a 403 status
      if (err.response && err.response.status === 403) {
        // Clear local storage if 403 error occurs
        localStorage.clear();
        // Optionally, redirect the user to the login page
        window.location.href = '/admin/login'; // Redirect to login or show a message
      } else {
        console.log('Error fetching admin details:', err);
        // Handle other errors (e.g., network issues) if needed
      }
    }
  };
  


  export const getSingleBuyerDetails = async () => {
    try{
    const response = await axiosInstance.get(`/buyer/get/by/token`);
    return response.data;
    }catch(err){
      console.log('err in buyer get by token',err)
    }
  };


  export const isDealerExistCheckFunction = async (mobile) => {
    try {
      const response = await axiosInstance.post("/dealer/registered", { mobile: mobile });
      return response.data;
    } catch (error) {
      // Check for an HTTP error and provide specific details
      if (error.response) {
        // Server responded with a status code other than 2xx
        const errorMessage = error.response.data?.message || "An error occurred while checking dealer registration.";
        alert(`Server Error: ${errorMessage}`);
      } else if (error.request) {
        // Request was made but no response received
        alert("Network Error: No response received from the server. Please check your connection.");
      } else {
        // Other errors (e.g., programming errors, configuration issues)
        alert(`Unexpected Error: ${error.message}`);
      }
      console.error("Error details:", error);
    }
  };
  

  export const getProductDetailsById = async (id) => {
    try{
    const response = await axiosInstance.get(`/products/${id}`);
    return response.data;
    }catch(err){
           console.log('err in single product get ',err)
    }
  };


  
  

  export const updateSingleBuyerDetails = async (editableProduct) => {
    try{
    const response = await axiosInstance.put(`/buyer/update/by/token`, editableProduct, {
      headers: {
        "Content-Type": "multipart/form-data", // Use multipart/form-data for file upload
      },
    });
    return response.data;
  }catch(err) {
      console.log('err',err)
  }
  };
  

  export const updateSingleAdminDetails = async (editableProduct) => {
    try{
    const response = await axiosInstance.put(`/admin/update/by/token`, editableProduct, {
      headers: {
        "Content-Type": "multipart/form-data", // Use multipart/form-data for file upload
      },
    });
    return response.data;
  }catch(err){
     console.log('errr',err)
  }
  };
  


  export const sendOtp = async (phoneNumber) => {
    try{
      if (!phoneNumber) return alert("Enter a valid phone number");
      try {
      const  response = await axiosInstance.post("/otp/send-otp", { contactNumber: phoneNumber });
      return response;
      } catch (error) {
        console.error("Failed to send OTP:", error);
        alert("Failed to send OTP");
      }
    }catch(err){
      console.log('err',err)
    }
    };


  export const verifyOtp = async (phoneNumber,otp) => {
        try {
          const response = await axiosInstance.post("/otp/verify-otp", {
            contactNumber: phoneNumber,
            otp,
          });
            return response;
        } catch (error) {
          console.error("Verification failed:", error);
          alert("OTP verification failed");
        }
      };


  

