
// import { lazy, Suspense, useEffect, useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
// import NavBar from "./components/Navbar/Navbar";
// import Footer from "./components/Footer/Footer";
// import Loader from "./components/Loader/Loader";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import StoreProduct from "./pages/StoreProduct";
// import ProductDashboard from "./pages/ProductDashboard";
// import TermsAndConditions from "./pages/TermsAndConditions";
// import AdminLoginComponent from "./pages/adminLogin";
// import AdminDashboard from "./pages/AdminDashboard";
// import AdminProfile from "./pages/AdminProfile";

// import { requestFCMToken } from "./utils/firebaseUtils";
// import { updateSingleAdminDetails, updateSingleBuyerDetails } from "./utils/apiCall";
// const Home = lazy(() => import("./pages/Home"));
// const Shop = lazy(() => import("./pages/Shop"));
// const Cart = lazy(() => import("./pages/Cart"));
// const Product = lazy(() => import("./pages/Product"));

// function App() {

   

//    const [fcmToken,setFcmToken] =useState(null);

//    useEffect(()=>{
//     fetchFCMToken();
//    },[])


//    const storeinAdminTable=async(token)=>{
//     const result2 = await updateSingleAdminDetails({fcmToken : token})
//     console.log('result 2',result2)
//    }

//    const fetchFCMToken = async () =>{
//     try{
//            const token = await requestFCMToken();
//            if(token){
//              storeinAdminTable(token)
//            }
//            console.log(token)
//            setFcmToken(token);
//     }catch(error){
//           console.log('error getting token',error)
//     }
//   }




//   // Service worker registration (in main entry file, e.g., index.js)
// // Register Service Worker

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/firebase-messaging-sw.js')
//     .then((registration) => {
//       console.log('Service Worker successfully registered:', registration);
//     })
//     .catch((error) => {
//       console.error('Service Worker registration failed:', error);
//     });
// }

//   return (
//     <div
//       className="bg-[#d6e5fb]"
//     >
//     <Router> {/* Ensure the Router wraps all components */}
//       <Suspense fallback={<Loader />}>
//         <ToastContainer
//           position="top-right"
//           autoClose={1000}
//           hideProgressBar={false}
//           newestOnTop={false}
//           closeOnClick
//           pauseOnFocusLoss
//           draggable
//           pauseOnHover
//           theme="light"
//         />
//         <MainContent />
//       </Suspense>
//     </Router>
//     </div>
//   );
// }

// // Separate component for conditionally rendering NavBar and Footer
// const MainContent = () => {

//   const [type,setType] = useState('');

//   useEffect(()=>{
//      const type = localStorage.getItem("buyer");
//      setType(type)
//   },[])

//   const location = useLocation(); // Get current route


//   const isDashboardRoute = location.pathname === "/adminlogin"; // Check if on /dashboard route
//   // const isLoginPage = location.pathname === "/user/login";
//   // console.log(isLoginPage)
//   return (
//     <>
//       {/* Conditionally render NavBar */}
//       {!isDashboardRoute &&<NavBar />} 
//       {/* Main content */}
//       <Routes>
    
//        { type === 'admin' ?
//         ( 
//         <>
//         <Route path="/admin/dashboard" element={<AdminDashboard/>} />
//         <Route path="/admin/profile" element={<AdminProfile/>} />
//         <Route path="/dashboard" element={<ProductDashboard />} />
//         <Route path="/terms-conditions" element={<TermsAndConditions />} />
//         <Route path="/product-upload" element={<StoreProduct />} />
//         </>
//         ) :
//          (
//           <>
//          <Route path="/admin/login" element={<AdminLoginComponent />} />
//         </>
//         ) }

       
//       </Routes>
//       {/* Conditionally render Footer */}
//       {!isDashboardRoute && <Footer />} {/* Hide Footer on /dashboard */}
//     </>
//   );
// };

// export default App;









import { lazy, Suspense, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";
import NavBar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Loader from "./components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StoreProduct from "./pages/StoreProduct";
import ProductDashboard from "./pages/ProductDashboard";
import TermsAndConditions from "./pages/TermsAndConditions";
import AdminLoginComponent from "./pages/adminLogin";
import AdminDashboard from "./pages/AdminDashboard";
import AdminProfile from "./pages/AdminProfile";

// import { requestFCMToken } from "./utils/firebaseUtils";
// import { updateSingleAdminDetails } from "./utils/apiCall";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { monitorSession } from "./utils/firebaseMonitorSession";
import { useDispatch, useSelector } from "react-redux";


function App() {
  // const [fcmToken, setFcmToken] = useState('')

  // useEffect(() => {
  //   // fetchFCMToken();
  // }, []);

  // const storeinAdminTable = async (token) => {
  //   const result2 = await updateSingleAdminDetails({ fcmToken: token });
  //   console.log("result 2", result2);
  // };

  // const fetchFCMToken = async () => {
  //   try {
  //     const token = await requestFCMToken();
  //     if (token) {
  //       storeinAdminTable(token);
  //     }
  //     console.log(token);
  //     setFcmToken(token);
  //   } catch (error) {
  //     console.log("error getting token", error);
  //   }
  // };

  // if ("serviceWorker" in navigator) {
  //   navigator.serviceWorker
  //     .register("/firebase-messaging-sw.js")
  //     .then((registration) => {
  //       console.log("Service Worker successfully registered:", registration);
  //     })
  //     .catch((error) => {
  //       console.error("Service Worker registration failed:", error);
  //     });
  // }

  return (
    <div className="bg-[#d6e5fb]">
      <Router>
        <Suspense fallback={<Loader />}>
          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <MainContent />
        </Suspense>
      </Router>
    </div>
  );
}

const MainContent = () => {

  const token = useSelector((state) => state.auth.token);


  const [type, setType] = useState("");
  const location = useLocation();

  const dispatch = useDispatch()
  
  const auth = getAuth();
  const navigate = useNavigate(); // React Router for navigation

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is logged in, start monitoring the session
        monitorSession(user.uid, navigate,dispatch);
      }
    });

    // Cleanup the listener when component unmounts
    return () => unsubscribe();
  }, [auth, navigate]);



  useEffect(() => {
    const storedType = localStorage.getItem("buyer") || "";
    setType(storedType);
  }, []);

  // Conditional Rendering Logic
  const isAdmin = type === "admin";
  const isAdminLoginRoute = location.pathname === "/admin/login";

  return (
    <>
      {/* Conditionally render NavBar */}
      {!isAdminLoginRoute && <NavBar />}
      <Routes>
        {/* Admin Routes */}
        {token ? (
          <>
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            <Route path="/admin/profile" element={<AdminProfile />} />
            <Route path="/dashboard" element={<ProductDashboard />} />
            <Route path="/terms-conditions" element={<TermsAndConditions />} />
            <Route path="/product-upload" element={<StoreProduct />} />
            <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
          </>
        ) : (
          <>
            {/* Redirect unauthorized users to login */}
            <Route path="/admin/login" element={<AdminLoginComponent />} />
            <Route path="*" element={<Navigate to="/admin/login" replace />} />
          </>
        )}
      </Routes>
      {/* Conditionally render Footer */}
      {!isAdminLoginRoute && <Footer />}
    </>
  );
};

export default App;
