
// firebase.js
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, onValue, remove } from 'firebase/database';
import { getAuth } from 'firebase/auth';

// Your Firebase Config
const firebaseConfig = {
  apiKey: "AIzaSyCIiSrLeRlHlv5VKhQm-LNqBLG3by6nFHg",
  authDomain: "ratefry-ad76f.firebaseapp.com",
  projectId: "ratefry-ad76f",
  storageBucket: "ratefry-ad76f.firebasestorage.app",
  messagingSenderId: "408098317933",
  appId: "1:408098317933:web:1240901e06b714945e8164",
  measurementId: "G-KW0JRHBH3S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export authentication & database
const auth = getAuth();
const database = getDatabase(app);

export { auth, database, ref, set, onValue, remove };
