

import React from "react";
import { useNavigate } from "react-router-dom"; // Importing useNavigate for navigation
import ProfileSection from "../components/AdminDashboardComponent/ProfileSection";
import SellersList from "../components/AdminDashboardComponent/sellersList";

const Dashboard = () => {

  const navigate = useNavigate(); // Initialize useNavigate hook

  // Function to navigate to the home page
  const handleBackClick = () => {
    navigate("/"); // Navigate to the home page (you can modify the path as needed)
  };


  return (
    <div className="min-h-screen bg-gray-100 pt-8 p-2 mt-24"> {/* Added pt-8 to add space from top */}
      {/* Back Arrow Icon to navigate to home */}
    
      {/* Responsive Grid Layout */}
      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mb-32">
        <div>
          <ProfileSection />
        </div>
      </div>
      <SellersList />
    </div>
  );
};

export default Dashboard;
