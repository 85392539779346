// axiosInstance.js

import axios from 'axios';
import { store } from '../app/store';

// const apiUrl = 'http://localhost:5022/api/electronics';
const apiUrl = 'https://ratefry.com/api/electronics'; // Your API base URL

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});


// Get the token from Redux store
const getAuthTokenFromRedux = () => store.getState().auth.token;

// Function to set the token in the headers (from Redux)
const setAuthToken = (token1) => {
  const token = getAuthTokenFromRedux(); // Get token from Redux state
  const data =  token || token1 
  if (data) {
    axiosInstance.defaults.headers['Authorization'] = `Bearer ${data}`;
  } else {
    delete axiosInstance.defaults.headers['Authorization'];
  }
};

// Call setAuthToken when the app is initialized
setAuthToken();

export { axiosInstance, setAuthToken };
