// import React, { useState } from "react";
// import { Modal, Button, Form } from "react-bootstrap";
// import { toast } from "react-toastify";
// import { axiosInstance } from "../../utils/axios";
// import { useNavigate } from "react-router-dom";


// const UserRegisterModal = ({ showModal, onClose, onRegister }) => {
//   const [name, setName] = useState("");
//   const [mobile, setMobile] = useState("");
//   const [email, setEmail] = useState("");
//   const [password,setPassword] = useState("");


//   const navigate = useNavigate(); // For redirecting to login page

//   const handleSubmit =async (e) => {
//     e.preventDefault();
//     if (!name || !mobile || !email) {
//       toast.error("All fields are required!");
//       return;
//     }
//     // Trigger register buyer
//     const loginData = { 
//       name,
//       mobile,
//       email,
//       password,
//     };
//     // Send login request to the server
//     const response = await axiosInstance.post('/buyers', loginData);
    
//     console.log('respone is ehere',response)
//     onRegister(name, mobile, email);
//     // Reset form fields
//     setName("");
//     setMobile("");
//     setEmail("");
//     onClose(); // Close the modal after registration
//   };


//   const handleRedirectToLogin = () => {
//     navigate("/user/login"); // Redirect to the login page
//     onClose(); // Close the modal
//   };

//   return (
//     <Modal show={showModal} onHide={onClose}>
//       <Modal.Header closeButton>
//         <Modal.Title>Register</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Form onSubmit={handleSubmit}>
//           <Form.Group controlId="formName">
//             <Form.Label>Name</Form.Label>
//             <Form.Control
//               type="text"
//               value={name}
//               onChange={(e) => setName(e.target.value)}
//               placeholder="Enter your name"
//             />
//           </Form.Group>

//           <Form.Group controlId="formMobile">
//             <Form.Label>Mobile</Form.Label>
//             <Form.Control
//               type="text"
//               value={mobile}
//               onChange={(e) => setMobile(e.target.value)}
//               placeholder="Enter your mobile"
//             />
//           </Form.Group>

//           <Form.Group controlId="formEmail">
//             <Form.Label>Email</Form.Label>
//             <Form.Control
//               type="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               placeholder="Enter your email"
//             />
//           </Form.Group>

//           <Form.Group controlId="formEmail">
//             <Form.Label>Password</Form.Label>
//             <Form.Control
//               type="password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               placeholder="Enter your password"
//             />
//           </Form.Group>

//           <Button variant="primary" type="submit" className="m-2">
//             Register
//           </Button>

//           <Button variant="primary" className="bg-green-300" onClick={handleRedirectToLogin}>
//               Go to Login
//             </Button>
//         </Form>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default UserRegisterModal;








import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { axiosInstance } from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import { sendOtp, verifyOtp } from "../../utils/apiCall";

const UserRegisterModal = ({ showModal, onClose, onRegister }) => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState(""); // To store user-entered OTP
  const [otpSent, setOtpSent] = useState(false); // Track if OTP has been sent
  const [otpVerified, setOtpVerified] = useState(false); // Track OTP verification
  const navigate = useNavigate();

  const handlesendOtp = async () => {
    if (!mobile) {
      toast.error("Please enter a valid mobile number");
      return;
    }
    try {
      const response = await sendOtp(mobile)
      if (response.status === 200) {
        setOtpSent(true);
        toast.success("OTP sent successfully to mobile");
      } else {
        toast.error("Failed to send OTP");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("Error sending OTP");
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp) {
      toast.error("Please enter an OTP");
      return;
    }
    try {
      const response = await verifyOtp(mobile, otp );
      if (response) {
        setOtpVerified(true);
        toast.success("OTP verified. You can now register.");
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Error verifying OTP");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !mobile || !email || !otpVerified) {
      toast.error("Please fill all fields and verify OTP first!");
      return;
    }
    const loginData = {
      name,
      mobile,
      email,
      password,
    };

    try {
      const response = await axiosInstance.post('/buyers', loginData);
      console.log('Response:', response);
      onRegister(name, mobile, email);
      setName("");
      setMobile("");
      setEmail("");
      setPassword("");
      onClose();
      toast.success("User registered successfully!");
    } catch (error) {
      console.error("Error during registration", error);
      toast.error("Failed to register user.");
    }
  };

  const handleRedirectToLogin = () => {
    navigate("/user/login");
    onClose();
  };

  return (
    <Modal show={showModal} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>User Registration with OTP</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* User Info */}
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
            />
          </Form.Group>

          <Form.Group controlId="formMobile">
            <Form.Label>Mobile</Form.Label>
            <Form.Control
              type="text"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder="Enter your mobile"
            />
          </Form.Group>

          <Button
            variant="success"
            className="mt-2"
            onClick={handlesendOtp}
          >
            Send OTP
          </Button>

          {otpSent && (
            <>
              <Form.Group controlId="formOtp">
                <Form.Label>Enter OTP</Form.Label>
                <Form.Control
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="Enter OTP"
                />
              </Form.Group>
              <Button
                variant="primary"
                className="mt-2"
                onClick={handleVerifyOtp}
              >
                Verify OTP
              </Button>
            </>
          )}

          {otpVerified && (
            <>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                />
              </Form.Group>
              <Form.Group controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                />
              </Form.Group>
              <Button
                variant="primary"
                className="mt-3"
                onClick={handleSubmit}
              >
                Register
              </Button>
            </>
          )}

          <Button
            variant="secondary"
            className="mt-2"
            onClick={handleRedirectToLogin}
          >
            Go to Login
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UserRegisterModal;
