




// import React, { useEffect, useState } from "react";
// import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
// import L from "leaflet";
// import "leaflet/dist/leaflet.css";

// import { MdMyLocation } from "react-icons/md";

// const CurrentLocationMarker = ({ position, onDragEnd }) => {
//   // Fix for marker icon (default)
//   const DefaultIcon = new L.Icon({
//     iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
//     iconRetinaUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png",
//     shadowUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png",
//     iconSize: [25, 41],
//     iconAnchor: [12, 41],
//   });

//   L.Marker.prototype.options.icon = DefaultIcon;

//   const map = useMap();

//   // Update map's center when position changes
//   useEffect(() => {
//     if (position) {
//       map.setView(position, 15); // Set map center to current position
//     }
//   }, [position, map]);

//   const handleDragEnd = (event) => {
//     const newLatLng = event.target.getLatLng();
//     onDragEnd(newLatLng); // Notify parent component about the new position
//   };

//   return position ? (
//     <Marker
//       position={position}
//       draggable={true}
//       eventHandlers={{ dragend: handleDragEnd }} // Trigger on drag end
//     >
//       <Popup>
//         <span>Drag me!</span>
//       </Popup>
//     </Marker>
//   ) : null;
// };

// const CurrentLocationMap = ({onLocationUpdate}) => {
//   const [position, setPosition] = useState(null); // User's current location
//   const [latLng, setLatLng] = useState({ lat: null, lng: null }); // Track marker position




//   const handleGeolocationError = (error) => {
//     console.error("Geolocation Error: ", error);
//     alert("Please turn on your location services and allow permissions.");
//   };

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (location) => {
//           const { latitude, longitude } = location.coords;
//           setPosition([latitude, longitude]);
//           setLatLng({ lat: latitude, lng: longitude });
//           onLocationUpdate({ lat: latitude, lng: longitude });
//         },
//         (error) => {
//           handleGeolocationError(error);
//         }
//       );
//     } else {
//       alert("Geolocation is not supported by your browser.");
//     }
//   }, []);

  
//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (location) => {
//           const { latitude, longitude } = location.coords;
//           setPosition([latitude, longitude]); // Set initial position
//           setLatLng({ lat: latitude, lng: longitude }); // Set initial latitude/longitude
//         },
//         (error) => {
//           console.error("Geolocation Error:", error);
//           setPosition([28.6139, 77.2090]); // Fallback: New Delhi
//           setLatLng({ lat: 28.6139, lng: 77.2090 }); // Fallback
//         }
//       );
//     } else {
//       console.error("Geolocation not supported by this browser.");
//       setPosition([28.6139, 77.2090]); // Fallback
//       setLatLng({ lat: 28.6139, lng: 77.2090 }); // Fallback
//     }
//   }, []);

//   const handleMarkerDragEnd = (newLatLng) => {
//     setLatLng({ lat: newLatLng.lat, lng: newLatLng.lng }); // Update latitude and longitude when marker is dragged
//   };

//   useEffect(()=>{
//     onLocationUpdate({ lat: latLng.lat, lng: latLng.lng });
//   },[latLng])

//   const handleCenterOnCurrentLocation = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (location) => {
//           const { latitude, longitude } = location.coords;
//           setPosition([latitude, longitude]);
//           setLatLng({ lat: latitude, lng: longitude });
//         },
//         (error) => {
//           console.error("Error re-centering to current location: ", error);
//         }
//       );
//     } else {
//       console.error("Geolocation not supported by this browser.");
//     }
//   };

//   return (
//     <div>
//       <MapContainer
//         center={position || [28.6139, 77.2090]} // Fallback center if position is null
//         zoom={15}
//         style={{ height: "300px", width: "95%" }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         <CurrentLocationMarker position={position} onDragEnd={handleMarkerDragEnd} />
//       </MapContainer>

//       {/* Center on Current Location Button */}
//       <div
//         style={{
//           padding: "10px",
//           textAlign: "center",
//           backgroundColor: "#f0f0f0",
//           margin: "10px",
//         }}
//       >
//         <button
//           onClick={handleCenterOnCurrentLocation}
//           style={{
//             padding: "10px 20px",
//             backgroundColor: "#007bff",
//             color: "white",
//             border: "none",
//             borderRadius: "5px",
//             cursor: "pointer",
//           }}
//         >
//           <MdMyLocation style={{ marginRight: "5px" }} />
//         </button>
//       </div>

//       {/* Display Latitude and Longitude */}
//     </div>
//   );
// };

// export default CurrentLocationMap;










// import React, { useEffect, useState } from "react";
// import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
// import L from "leaflet";
// import "leaflet/dist/leaflet.css";
// import { MdMyLocation } from "react-icons/md";

// const CurrentLocationMarker = ({ position, onDragEnd }) => {
//   // Fix for marker icon (default)
//   const DefaultIcon = new L.Icon({
//     iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
//     iconRetinaUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png",
//     shadowUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png",
//     iconSize: [25, 41],
//     iconAnchor: [12, 41],
//   });

//   L.Marker.prototype.options.icon = DefaultIcon;

//   const map = useMap();

//   // Update map's center when position changes
//   useEffect(() => {
//     if (position) {
//       map.setView(position, 15); // Set map center to current position
//     }
//   }, [position, map]);

//   const handleDragEnd = (event) => {
//     const newLatLng = event.target.getLatLng();
//     onDragEnd(newLatLng); // Notify parent component about the new position
//   };

//   return position ? (
//     <Marker
//       position={position}
//       draggable={true}
//       eventHandlers={{ dragend: handleDragEnd }} // Trigger on drag end
//     >
//       <Popup>
//         <span>Drag me!</span>
//       </Popup>
//     </Marker>
//   ) : null;
// };

// const CurrentLocationMap = ({ onLocationUpdate }) => {
//   const [position, setPosition] = useState(null); // User's current location
//   const [latLng, setLatLng] = useState({ lat: null, lng: null }); // Track marker position
//   const [latitudeInput, setLatitudeInput] = useState(""); // Store latitude from user input
//   const [longitudeInput, setLongitudeInput] = useState(""); // Store longitude from user input

//   const handleGeolocationError = (error) => {
//     console.error("Geolocation Error: ", error);
//     alert("Please turn on your location services and allow permissions.");
//   };

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (location) => {
//           const { latitude, longitude } = location.coords;
//           setPosition([latitude, longitude]);
//           setLatLng({ lat: latitude, lng: longitude });
//           onLocationUpdate({ lat: latitude, lng: longitude }); // Return initial position to parent
//         },
//         (error) => {
//           handleGeolocationError(error);
//         }
//       );
//     } else {
//       alert("Geolocation is not supported by your browser.");
//     }
//   }, []);

//   const handleMarkerDragEnd = (newLatLng) => {
//     setLatLng({ lat: newLatLng.lat, lng: newLatLng.lng }); // Update latitude and longitude when marker is dragged
//     onLocationUpdate({ lat: newLatLng.lat, lng: newLatLng.lng }); // Return new position to parent
//   };

//   useEffect(() => {
//     onLocationUpdate({ lat: latLng.lat, lng: latLng.lng });
//   }, [latLng]);

//   const handleCenterOnCurrentLocation = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (location) => {
//           const { latitude, longitude } = location.coords;
//           setPosition([latitude, longitude]);
//           setLatLng({ lat: latitude, lng: longitude });
//           setLatitudeInput(""); // Clear input fields
//           setLongitudeInput(""); // Clear input fields
//           onLocationUpdate({ lat: latitude, lng: longitude }); // Return current position to parent
//         },
//         (error) => {
//           console.error("Error re-centering to current location: ", error);
//         }
//       );
//     } else {
//       console.error("Geolocation not supported by this browser.");
//     }
//   };

//   const handleSetLocationFromInput = () => {
//     const lat = parseFloat(latitudeInput);
//     const lng = parseFloat(longitudeInput);
//     if (!isNaN(lat) && !isNaN(lng)) {
//       setPosition([lat, lng]);
//       setLatLng({ lat, lng });
//       onLocationUpdate({ lat, lng }); // Return updated position to parent
//     } else {
//       alert("Please enter valid latitude and longitude values.");
//     }
//   };

//   return (
//     <div>
//       {/* Latitude and Longitude Inputs */}
//       <div style={{ padding: "10px", textAlign: "center" }}>
//         <input
//           type="number"
//           step="any"
//           value={latitudeInput}
//           onChange={(e) => setLatitudeInput(e.target.value)}
//           placeholder="Enter Latitude"
//           style={{ padding: "5px", margin: "5px", width: "200px" }}
//         />
//         <input
//           type="number"
//           step="any"
//           value={longitudeInput}
//           onChange={(e) => setLongitudeInput(e.target.value)}
//           placeholder="Enter Longitude"
//           style={{ padding: "5px", margin: "5px", width: "200px" }}
//         />
//         <button
//           onClick={handleSetLocationFromInput}
//           style={{
//             padding: "5px 10px",
//             backgroundColor: "#007bff",
//             color: "white",
//             border: "none",
//             borderRadius: "5px",
//             cursor: "pointer",
//             marginLeft: "10px",
//           }}
//         >
//           OK
//         </button>
//       </div>

//       {/* Map Container */}
//       <MapContainer
//         center={position || [28.6139, 77.2090]} // Fallback center if position is null
//         zoom={15}
//         style={{ height: "300px", width: "95%" }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         <CurrentLocationMarker position={position} onDragEnd={handleMarkerDragEnd} />
//       </MapContainer>

//       {/* Center on Current Location Button */}
//       <div
//         style={{
//           padding: "10px",
//           textAlign: "center",
//           backgroundColor: "#f0f0f0",
//           margin: "10px",
//         }}
//       >
//         <button
//           onClick={handleCenterOnCurrentLocation}
//           style={{
//             padding: "10px 20px",
//             backgroundColor: "#007bff",
//             color: "white",
//             border: "none",
//             borderRadius: "5px",
//             cursor: "pointer",
//           }}
//         >
//           <MdMyLocation style={{ marginRight: "5px" }} />
//         </button>
//       </div>

//       {/* Display Latitude and Longitude */}
//     </div>
//   );
// };

// export default CurrentLocationMap;





import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { MdMyLocation } from "react-icons/md";

const CurrentLocationMarker = ({ position, onDragEnd }) => {
  const DefaultIcon = new L.Icon({
    iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
    iconRetinaUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png",
    shadowUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  const map = useMap();

  useEffect(() => {
    if (position) {
      map.setView(position, 15);
    }
  }, [position, map]);

  const handleDragEnd = (event) => {
    const newLatLng = event.target.getLatLng();
    onDragEnd(newLatLng);
  };

  return position ? (
    <Marker position={position} draggable={true} eventHandlers={{ dragend: handleDragEnd }}>
      <Popup>
        <span>Drag me!</span>
      </Popup>
    </Marker>
  ) : null;
};

const CurrentLocationMap = ({ onLocationUpdate }) => {
  const [position, setPosition] = useState(null);
  const [latLng, setLatLng] = useState({ lat: null, lng: null });
  const [latitudeInput, setLatitudeInput] = useState("");
  const [longitudeInput, setLongitudeInput] = useState("");
  const [urlInput, setUrlInput] = useState("");

  const handleGeolocationError = (error) => {
    console.error("Geolocation Error: ", error);
    alert("Please turn on your location services and allow permissions.");
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (location) => {
          const { latitude, longitude } = location.coords;
          setPosition([latitude, longitude]);
          setLatLng({ lat: latitude, lng: longitude });
          onLocationUpdate({ lat: latitude, lng: longitude,userLocationUrl: urlInput });
        },
        (error) => {
          handleGeolocationError(error);
        }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
    }
  }, []);

  const handleMarkerDragEnd = (newLatLng) => {
    setLatLng({ lat: newLatLng.lat, lng: newLatLng.lng });
    onLocationUpdate({ lat: newLatLng.lat, lng: newLatLng.lng,userLocationUrl: urlInput });
  };

  useEffect(() => {
    onLocationUpdate({ lat: latLng.lat, lng: latLng.lng,userLocationUrl: urlInput });
  }, [latLng]);

  const handleCenterOnCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (location) => {
          const { latitude, longitude } = location.coords;
          setPosition([latitude, longitude]);
          setLatLng({ lat: latitude, lng: longitude });
          setLatitudeInput(""); // Clear input fields
          setLongitudeInput(""); // Clear input fields
          setUrlInput("");
          onLocationUpdate({ lat: latitude, lng: longitude,userLocationUrl: urlInput });
        },
        (error) => {
          console.error("Error re-centering to current location: ", error);
        }
      );
    } else {
      console.error("Geolocation not supported by this browser.");
    }
  };

  const handleSetLocationFromInput = () => {
    const lat = parseFloat(latitudeInput);
    const lng = parseFloat(longitudeInput);
    if (!isNaN(lat) && !isNaN(lng)) {
      setPosition([lat, lng]);
      setLatLng({ lat, lng });
      onLocationUpdate({ lat, lng,userLocationUrl: urlInput });
    } else {
      alert("Please enter valid latitude and longitude values.");
    }
  };

  const handleUrlChange = (e) => {
    setUrlInput(e.target.value);
  };

  const handleExtractCoordinatesFromUrl = () => {
    const url = urlInput.trim();
    let lat, lng;

    // Regex to match URLs containing latitude and longitude after @ or q=
    const regexAt = /@([\-0-9.]+),([\-0-9.]+)/; // Matches @lat,lng
    const regexQ = /q=([\-0-9.]+),([\-0-9.]+)/; // Matches q=lat,lng

    // Try to match with @ and q= regex patterns
    const matchAt = url.match(regexAt);
    const matchQ = url.match(regexQ);

    if (matchAt) {
      lat = matchAt[1];
      lng = matchAt[2];
    } else if (matchQ) {
      lat = matchQ[1];
      lng = matchQ[2];
    }

    if (lat && lng) {
      setLatitudeInput(lat);
      setLongitudeInput(lng);
      setPosition([parseFloat(lat), parseFloat(lng)]);
      setLatLng({ lat: parseFloat(lat), lng: parseFloat(lng) });
      onLocationUpdate({ lat: parseFloat(lat), lng: parseFloat(lng),userLocationUrl: urlInput });
    } else {
      alert("Invalid URL, cannot extract latitude and longitude.");
    }
  };

  const handleClearUrlInput = () => {
    setUrlInput(""); // Clear the URL input field
  };

  return (
    <div>
      {/* Latitude and Longitude Inputs */}
      <div style={{ padding: "10px", textAlign: "center" }}>
        <input
          type="number"
          step="any"
          value={latitudeInput}
          onChange={(e) => setLatitudeInput(e.target.value)}
          placeholder="Enter Latitude"
          style={{ padding: "5px", margin: "5px", width: "200px" }}
        />
        <input
          type="number"
          step="any"
          value={longitudeInput}
          onChange={(e) => setLongitudeInput(e.target.value)}
          placeholder="Enter Longitude"
          style={{ padding: "5px", margin: "5px", width: "200px" }}
        />
        <button
          onClick={handleSetLocationFromInput}
          style={{
            padding: "5px 10px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            marginLeft: "10px",
          }}
        >
          OK
        </button>
      </div>

      {/* URL Input Field */}
      <div style={{ padding: "10px", textAlign: "center" }}>
      <input
          type="text"
          value={urlInput}
          onChange={handleUrlChange}
          placeholder="Enter Google Maps URL"
          className="p-3 mt-2 mb-3 w-full max-w-lg mx-auto border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
        />

        <button
          onClick={handleExtractCoordinatesFromUrl}
          style={{
            padding: "5px 10px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            marginLeft: "10px",
          }}
        >
          Extract Coordinates
        </button>
        <button
          onClick={handleClearUrlInput}
          style={{
            padding: "5px 10px",
            backgroundColor: "#f44336",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            marginLeft: "10px",
          }}
        >
          Clear
        </button>
      </div>

      {/* Map Container */}
      <MapContainer center={position || [28.6139, 77.2090]} zoom={15} style={{ height: "300px", width: "95%" }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <CurrentLocationMarker position={position} onDragEnd={handleMarkerDragEnd} />
      </MapContainer>

      {/* Center on Current Location Button */}
      <div
        style={{
          padding: "10px",
          textAlign: "center",
          backgroundColor: "#f0f0f0",
          margin: "10px",
        }}
      >
        <button
          onClick={handleCenterOnCurrentLocation}
          style={{
            padding: "10px 20px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          <MdMyLocation style={{ marginRight: "5px" }} />
        </button>
      </div>
    </div>
  );
};

export default CurrentLocationMap;
