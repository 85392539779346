





import React, { useState, useRef, useEffect } from "react";
import { Navbar, Form, FormControl, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FiShoppingCart } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { FaHome } from "react-icons/fa";

import { removeToken } from "../../app/features/login/authSlice";
import { removeBuyer } from "../../app/features/login/buyerSlice";
import "./header.css";
import LocationPermissionModal from "./locationPermissionModal"; // Import modal
import AddSellerModal from "../AdminDashboardComponent/AddSellerModal";

const Header = () => {
  const buyer = useSelector((state) => state.buyer.buyer);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [locationPermissionModalOpen, setLocationPermissionModalOpen] = useState(false);

  const[search,setSearch] = useState('');

  const dropdownRef = useRef(null);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const toggleMenuDropdown = (e) => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  const requestLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => resolve(position),
          (error) => reject(error),
          {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0,
          }
        );
      } else {
        reject("Geolocation is not supported by this browser.");
      }
    });
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    const searchQuery = e.target.search.value;
  setSearch(searchQuery);
    try {
      const position = await requestLocation();
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      navigate(`/?query=${searchQuery}&latitude=${latitude}&longitude=${longitude}`);
    } catch (error) {
      console.error("Location error:", error);
      navigate(`/?query=${searchQuery}`);
      setLocationPermissionModalOpen(true);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("buyer");
    localStorage.removeItem("token");
    dispatch(removeToken());
    dispatch(removeBuyer());
    navigate("/login");
    window.location.reload();
  };

  const handleModalClose = () => {
    setLocationPermissionModalOpen(false);
  };

  const handleRetry = async () => {
    try {
      // alert('working')
      const position = await requestLocation();
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      navigate(`/?query=${search}&latitude=${latitude}&longitude=${longitude}`);
      setLocationPermissionModalOpen(false);
    } catch (error) {
      console.error("Retry failed - location still unavailable.");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  const handleNavigation = () => {
    navigate('/'); // Navigate to the home route
  };


  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);


  return (
    <>
      <Navbar bg="light" expand="lg" className="header-navbar fixed-top">
        <div className="container">
          <div className="header-top d-flex align-items-center justify-content-between">
            {/* Menu Icon */}
            <button className="menu-icon" 
            onClick={toggleMenuDropdown}
            onMouseEnter={() => setDropdownOpen(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                width="24px"
                height="24px"
              >
                <path d="M3 6h18M3 12h18M3 18h18" stroke="currentColor" strokeWidth="2" />
              </svg>
            </button>

            {/* Logo */}
            <Navbar.Brand as={Link} to="/" className="ml-5 mb-2">
              <img src="/logo.png" alt="Logo" className="header-logo" />
            </Navbar.Brand>
          </div>

         
          {/* Dropdown Menu */}
          {dropdownOpen && (
            <div className="menu-dropdown z-50" 
             ref={dropdownRef}
             onMouseEnter={() => setDropdownOpen(true)}
             onMouseLeave={() => setDropdownOpen(false)}
             >
            <div
              className="menu-dropdown-header cursor-pointer"
              onClick={handleNavigation}
            >
              <div
                style={{ backgroundColor: '#e2f2b2', color: 'black', borderColor: '#e2f2b2' }}
                className="p-2 flex items-center"
              >
                <FaHome size={20} />
              </div>
              <img
                className="h-6 w-24 cursor-pointer"
                src="https://www.ratefry.com/logo.png"
                alt="Logo"
                onClick={handleNavigation}
              />
            </div>

              <ul className="menu-dropdown-list">
                {!token && (
                  <>
                    <li onClick={() => navigate("/user/login")}>User Login</li>
                    <li onClick={() => navigate("/admin/login")}>Admin Login</li>
                  </>
                )}

              
                {/* {buyer === "admin" && (
                  <li onClick={() => navigate("/admin/dashboard")}>
                    <button type="button">Admin Dashboard</button>
                  </li>
                )} */}


                  {/* <li  onClick={handleShow}>
                    <button type="button">Dealer Register</button>
                  </li> */}
               


                {buyer === "buyer" && (
                  <li onClick={() => navigate("/user/profile")}>
                    <button type="button">User Profile</button>
                  </li>
                )}

                  {/* <li onClick={() => window.open("https://dealer.ratefry.com/login", "_blank")}>
                    <button type="button">Dealer Login</button>
                  </li> */}

                  {/* <li onClick={() => navigate("/dealer/register")}>
                    <button type="button">Dealer Register</button>
                  </li> */}

                 
                {token && (
                  <li onClick={handleLogout}>
                    <button type="button">Logout</button>
                  </li>
                )}
              </ul>
            </div>
          )}


                {/* <AddSellerModal
                  show={showModal}
                  handleClose={handleClose}
                /> */}

          {/* Search Bar */}
          {/* <div>
            <Form className="d-flex" onSubmit={handleSearch}>
              <FormControl
                type="search"
                name="search"
                placeholder="Search for Products, Brands, and More"
                className="me-2"
                aria-label="Search"
              />
              <Button
                variant="primary"
                type="submit"
                style={{ backgroundColor: '#e2f2b2', color: 'black', borderColor: '#e2f2b2' }}
              >
                Search
              </Button>
            </Form>
          </div> */}
        </div>
      </Navbar>

      {/* Modal */}
      <LocationPermissionModal
        show={locationPermissionModalOpen}
        handleClose={handleModalClose}
        handleRetry={handleRetry}
      />
    </>
  );
};

export default Header;